import React, { Component } from "react";
import {
  Button,
  Table,
  Radio,
  Input,
  Select,
  DatePicker,
  Cascader,
  Modal,
  Icon,
  Form,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import {
  _franchiseeList,
  _exceptionOrderList,
  _orderExceptionDetail,
  _exceptionOrderStatus,
  _exportExceptionOrder,
  _bikeRepairList,
  _siteRepairBoxList,
  _saveExceptionOrder,
  _queryExistOrder,
  _getSonsOfRegionPackage,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import queryString from 'query-string';
const { Search } = Input;
const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
const modalTitle = ["线下处理", "线下处理编辑", "线下处理详情"];
class ExceptionOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      exceptionOrderList: [], //线下处理列表
      franchiseeList: [], //代理商列表
      initialValues: {}, //详情
      queryFormValues: {}, //筛选项
      options: [], //地区
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      disableForEdit: false,
      isAutoCreate: false,
    };
    this.columns = [
      {
        title: "序号",
        width: 80,
        dataIndex: "id",
      },
      {
        title: "订单号",
        dataIndex: "orderSn",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "站点",
        dataIndex: "sname",
      },
      {
        title: "套餐名",
        width: 200,
        dataIndex: "pname",
      },
      {
        title: "套餐金额",
        dataIndex: "pmoney",
      },
      {
        title: "车辆",
        dataIndex: "imei",
      },
      {
        title: "车辆型号",
        dataIndex: "modelName",
      },
      {
        title: "车辆型号类型",
        dataIndex: "bikeSelf",
        render: (text) => (text ? "自营" : "非自营"),
      },
      {
        title: "电池",
        dataIndex: "batVinCode",
        render: (text) =>
          text ? <span style={{ whiteSpace: "pre-line" }}>{text}</span> : " 无",
      },
      {
        title: "电池型号",
        dataIndex: "batModelName",
        render: (text) =>
          text ? <span style={{ whiteSpace: "pre-line" }}>{text}</span> : " 无",
      },
      {
        title: "电池型号类型",
        dataIndex: "batterySelf",
        render: (text) => (text ? "自营" : "非自营"),
      },
      {
        title: "订单状态",
        dataIndex: "orderStatusName",
      },
      {
        title: "用户信息",
        dataIndex: "",
        width: 130,
        render: (text) => (
          <span>
            {text.userName}
            <br />
            {text.mobile}
          </span>
        ),
      },
      {
        title: "金额",
        dataIndex: "",
        width: 160,
        render: (text) => (
          <span>
            申请金额：
            {text.applyAmount}
            元<br />
            {text.status === 2 && (
              <span>
                审核金额：{text.amount}元<br /> 结算金额：{text.receiveAmount}元
              </span>
            )}
          </span>
        ),
      },
      {
        title: "天数",
        dataIndex: "",
        width: 160,
        render: (text) =>
          text.type !== 4 && (
            <span>
              申请天数：
              {text.applyDays}
              天<br />
              {text.status === 2 && <span>审核天数：{text.days}天</span>}
            </span>
          ),
      },
      {
        title: "处理类型",
        dataIndex: "type",
        render: (text) =>
          text === 1
            ? "订单延时"
            : text === 2
            ? "结束订单"
            : text === 3
            ? "订单退款"
            : "车辆维修",
      },
      {
        title: "处理状态",
        dataIndex: "status",
        width: 120,
        render: (text, record) =>
          text === 1 ? (
            "申请中"
          ) : text === 2 ? (
            <span>
              已通过
              <br />
              {moment(record.handleTime).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          ) : (
            <span>
              已拒绝
              <br />
              {moment(record.handleTime).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          ),
      },
      {
        title: "创建时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(904)}
              title={getJurisdiction(904) ? "" : "暂无操作权限！"}
              onClick={this.getOrderExceptionDetails.bind(this, text.id, 3)}
            >
              查看
            </Button>
            {text.status === 1 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(905)}
                  title={getJurisdiction(905) ? "" : "暂无操作权限！"}
                  onClick={this.getOrderExceptionDetails.bind(this, text.id, 2)}
                >
                  编辑
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(906)}
                  title={getJurisdiction(906) ? "" : "暂无操作权限！"}
                  onClick={this.handleExceptionOrderStatusChange.bind(this, {
                    ...text,
                  })}
                >
                  审核
                </Button>
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户/手机号">
              {getFieldDecorator("mobile")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="处理类型">
              {getFieldDecorator("typeSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">订单延时</Option>
                  <Option value="2">结束订单</Option>
                  <Option value="3">订单退款</Option>
                  <Option value="4">车辆维修</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="审核状态">
              {getFieldDecorator("statusSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">申请中</Option>
                  <Option value="2">已通过</Option>
                  <Option value="3">已拒绝</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="创建时间">
              {getFieldDecorator("addTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="审核时间">
              {getFieldDecorator("handleTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { totalCount, curPage, pageSize, exceptionOrderList, initialValues } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 5 },
        sm: { span: 12, offset: 5 },
        md: { span: 10 },
      },
    };
    return (
      <div>
        {getJurisdiction(903) ? (
          <div className="ExceptionOrderList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(908)}
                title={getJurisdiction(908) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                {modalTitle[0]}
              </Button>
            </div>
            <div className="content-table">
              <Table
                scroll={{ x: 2400, y: 500 }}
                rowKey="id"
                columns={this.columns}
                dataSource={exceptionOrderList}
                bordered
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
              okButtonProps={{ disabled: this.state.submitCheck }}
            >
              <Row>
                <Col style={{ display: "none" }} span={24}>
                  <Form.Item label="id">
                    {getFieldDecorator("id", {
                      initialValue: initialValues.id,
                    })(<span>{initialValues.id}</span>)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="订单号">
                    {this.state.modalType === 2 ||
                    this.state.modalType === 3 ? (
                      <span>{initialValues.orderSn}</span>
                    ) : (
                      getFieldDecorator("porderId", {
                        rules: [
                          { required: true, message: "请输入订单号！" },
                        ],
                      })(
                        <Search
                          disabled={this.state.isAutoCreate}
                          placeholder="请输入"
                          onSearch={this.getExistOrder.bind(this)}
                          style={{ width: 200 }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="处理类型">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.type === 1
                          ? "订单延时"
                          : initialValues.type === 2
                          ? "结束订单"
                          : initialValues.type === 3
                          ? "订单退款"
                          : "车辆维修"}
                      </span>
                    ) : (
                      getFieldDecorator("type", {
                        initialValue: initialValues.type,
                        rules: [{ required: true, message: "请选择类型" }],
                      })(
                        <Select
                          placeholder="请选择"
                          onChange={this.handleTypeChange.bind(this)}
                          allowClear
                          style={{ width: "100%" }}
                        >
                          <Option value={1}>订单延时</Option>
                          <Option value={2}>结束订单</Option>
                          <Option value={3}>订单退款</Option>
                          <Option
                            disabled={
                              this.state.modalType === 1 && !this.state.imei
                            }
                            value={4}
                          >
                            车辆维修
                          </Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                {this.state.abnormalType !== 4 && (
                  <Col span={24}>
                    <Form.Item label="申请天数">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.applyDays + "天"}</span>
                      ) : (
                        getFieldDecorator("applyDays", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的天数",
                              pattern: /^[+]{0,1}(\d+)$/,
                            },
                          ],
                          initialValue: initialValues.applyDays,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType !== 4 && (
                  <Col span={24}>
                    <Form.Item label="申请金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.applyAmount + "元"}</span>
                      ) : (
                        getFieldDecorator("applyAmount", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.applyAmount,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 2 && (
                  <Col span={24}>
                    <Form.Item label="包含租金金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.rentMoneyApply + "元"}</span>
                      ) : (
                        getFieldDecorator("rentMoneyApply", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.rentMoneyApply,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}

                {this.state.abnormalType === 2 && (
                  <Col span={24}>
                    <Form.Item label="包含违约金金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.penaltyMoneyApply + "元"}</span>
                      ) : (
                        getFieldDecorator("penaltyMoneyApply", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.penaltyMoneyApply,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 2 && (
                  <Col span={24}>
                    <Form.Item label="包含买断金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.buyoutMoneyApply + "元"}</span>
                      ) : (
                        getFieldDecorator("buyoutMoneyApply", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.buyoutMoneyApply,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="银行卡号">
                      {this.state.modalType === 3
                        ? initialValues.cardNo
                        : getFieldDecorator("cardNo", {
                            rules: [
                              {
                                required: true,
                                message: "请输入银行卡号",
                              },
                            ],
                            initialValue: initialValues.cardNo,
                          })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="开卡城市">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.areaName}</span>
                      ) : (
                        getFieldDecorator("areaList", {
                          rules: [
                            {
                              required:
                                this.state.modalType === 1 ? true : false,
                              message: "请输入开卡城市！",
                            },
                          ],
                        })(
                          <Cascader
                            placeholder={
                              initialValues.areaName && initialValues.areaName
                            }
                            style={{ width: "100%" }}
                            options={this.state.options}
                            loadData={this.getLoadData.bind(this)}
                            changeOnSelect
                          />
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="户主姓名">
                      {this.state.modalType === 3
                        ? initialValues.cardUserName
                        : getFieldDecorator("cardUserName", {
                            rules: [
                              {
                                required: true,
                                message: "请输入户主姓名",
                              },
                            ],
                            initialValue: initialValues.cardUserName,
                          })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="开户行">
                      {this.state.modalType === 3
                        ? initialValues.cardBankName
                        : getFieldDecorator("cardBankName", {
                            rules: [
                              {
                                required: true,
                                message: "请输入开户行",
                              },
                            ],
                            initialValue: initialValues.cardBankName,
                          })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="开户支行">
                      {this.state.modalType === 3
                        ? initialValues.branchBankName
                        : getFieldDecorator("branchBankName", {
                            rules: [
                              {
                                required: true,
                                message: "请输入开户支行",
                              },
                            ],
                            initialValue: initialValues.branchBankName,
                          })(
                            <Input
                              allowClear
                              placeholder="请输入完整的银行卡开户支行名称。一般格式为“XX银行XX市分行XX支行”"
                            />
                          )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="银行行号">
                      {this.state.modalType === 3
                        ? initialValues.bankNo
                        : getFieldDecorator("bankNo", {
                            initialValue: initialValues.bankNo,
                          })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 4 && (
                  <Col span={24}>
                    <Form.Item label="维修金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.applyAmount + "元"}</span>
                      ) : (
                        getFieldDecorator("applyAmount", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.applyAmount,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 4 && (
                  <Col span={24}>
                    <Form.Item label="退车点">
                      {this.state.modalType === 3
                        ? initialValues.rname
                        : getFieldDecorator("rid", {
                            rules: [
                              { required: true, message: "请选择退车点" },
                            ],
                            initialValue: initialValues.rid,
                          })(
                            <Select
                              placeholder="请选择"
                              allowClear
                              style={{ width: "100%" }}
                            >
                              {this.state.repairSiteList &&
                                this.state.repairSiteList.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.abnormalType === 4 && (
                  <Col span={24}>
                    {this.renderPeriodItems(
                      formItemLayout,
                      formItemLayoutWithOutLabel
                    )}
                  </Col>
                )}
                {initialValues.status === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="审核金额">
                      <span>{initialValues.amount + "元"}</span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.type === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="审核租金金额">
                      <span>{initialValues.rentMoney + "元"}</span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.type === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="审核违约金金额">
                      <span>{initialValues.penaltyMoney + "元"}</span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.type === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="审核买断金额">
                      <span>{initialValues.buyoutMoney + "元"}</span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.status === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="审核天数">
                      <span>{initialValues.days + "天"}</span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.status === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="结算金额">
                      <span>{initialValues.receiveAmount + "元"}</span>
                    </Form.Item>
                  </Col>
                )}
                {this.state.modalType !== 1 && (
                  <Col span={24}>
                    <Form.Item label="处理状态">
                      <span>
                        {initialValues.status === 1
                          ? "申请中"
                          : initialValues.status === 2
                          ? "已通过"
                          : "已拒绝"}
                      </span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.type === 1 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="处理类型">
                      <span>
                        {initialValues.reletType === 1
                          ? "新增"
                          : initialValues.reletType === 2
                          ? "续租"
                          : ""}
                      </span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues.type === 2 && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="处理类型">
                      <span>
                        {initialValues.reletType === 1
                          ? "新增"
                          : initialValues.reletType === 2
                          ? "续租"
                          : ""}
                      </span>
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="文字描述">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.content}</span>
                    ) : (
                      getFieldDecorator("content", {
                        initialValue: initialValues.content,
                      })(
                        <Input.TextArea
                          rows={4}
                          allowClear
                          placeholder="请输入"
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                {initialValues.remark && this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="备注">
                      <span>{initialValues.remark}</span>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    localStorage.getItem("type") === "0" && this.getFranchiseeList();
    this.getRepairSiteList();
    this.getBikeRepairList();
    this.getExceptionOrderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
    const { createByOrderSn } = queryString.parse(this.props.location.search);
    if (createByOrderSn) {
      this.setState({ isAutoCreate: true });
      this.showModal(1);
      this.props.form.setFieldsValue({ porderId: createByOrderSn });
      this.getExistOrder(createByOrderSn);
    }
  }

  handleTypeChange(value) {
    this.setState({
      abnormalType: value,
    });
  }

  //审核
  handleExceptionOrderStatusChange({
    id,
    orderSn,
    content,
    type,
    applyAmount,
    applyDays,
    cardNo,
    cardUserName,
    cardBankName,
    branchBankName,
    rname,
    repairParams,
    areaName,
    bankNo,
    rentMoneyApply,
    penaltyMoneyApply,
    buyoutMoneyApply,
  }) {
    let status,
      amount,
      receiveAmount,
      days,
      remark,
      reletType,
      rentMoney,
      penaltyMoney,
      buyoutMoney;
    let self = this;
    let repairList = [];
    repairParams &&
      repairParams.map((item, index) => {
        return self.state.bikeRepairList.map((items, indexs) => {
          if (Number(item.keyName) === items.id) {
            return repairList.push({ name: items.name, value: item.keyValue });
          }
          return items;
        });
      });
    Modal.confirm({
      title: `订单[${orderSn}]审核`,
      content: (
        <div className="diffModalDiv" style={{ marginTop: 30 }}>
          <p
            style={{
              borderBottom: "1px solid #e8e8e8",
              paddingBottom: 20,
            }}
          >
            处理类型：
            {type === 1
              ? "订单延期"
              : type === 2
              ? "结束订单"
              : type === 3
              ? "订单退款"
              : "车辆维修"}
            <br />
            申请金额：{applyAmount}元<br />
            {type === 2 && "申请租金金额：" + rentMoneyApply + "元"}
            {type === 2 && <br />}
            {type === 2 && "申请违约金金额：" + penaltyMoneyApply + "元"}
            {type === 2 && <br />}
            {type === 2 && "申请买断金额：" + buyoutMoneyApply + "元"}
            {type === 2 && <br />}
            {type !== 4 && "申请天数：" + applyDays + "天"}
            {type === 4 && (
              <p>
                退车点： {rname}
                <br />
                {repairList.map((item, index) => {
                  return (
                    <span>
                      <br />
                      {item.name + "：" + item.value + "元"}
                    </span>
                  );
                })}
              </p>
            )}
            {type === 3 && (
              <p>
                银行卡号：{cardNo}
                <br />
                开卡城市：{areaName}
                <br />
                户主姓名：{cardUserName}
                <br />
                开户行：{cardBankName}
                <br />
                开户支行：{branchBankName}
                <br />
                银行行号：{bankNo}
              </p>
            )}
            <p>商家描述：{content}</p>
          </p>
          <Form.Item style={{ marginBottom: 10 }} label="审核" required>
            <Radio.Group
              onChange={(e) => {
                status = e.target.value;
              }}
            >
              <Radio value={2}>通过</Radio>
              <Radio value={3}>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
          {(type === 1 || type === 2) && (
            <Form.Item style={{ marginBottom: 10 }} label="类型" required>
              <Radio.Group
                onChange={(e) => {
                  reletType = e.target.value;
                }}
              >
                <Radio value={1}>新增</Radio>
                <Radio value={2}>续租</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {type === 2 && (
            <Form.Item style={{ marginBottom: 10 }} label="审核租金金额">
              <Input
                style={{ width: 200 }}
                onChange={(e) => {
                  rentMoney = e.target.value;
                }}
                allowClear
                placeholder="请输入审核租金金额"
              />
            </Form.Item>
          )}
          {type === 2 && (
            <Form.Item style={{ marginBottom: 10 }} label="审核违约金金额">
              <Input
                style={{ width: 200 }}
                onChange={(e) => {
                  penaltyMoney = e.target.value;
                }}
                allowClear
                placeholder="请输入审核违约金金额"
              />
            </Form.Item>
          )}
          {type === 2 && (
            <Form.Item style={{ marginBottom: 10 }} label="审核买断金额">
              <Input
                style={{ width: 200 }}
                onChange={(e) => {
                  buyoutMoney = e.target.value;
                }}
                allowClear
                placeholder="请输入审核买断金额"
              />
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 10 }} label="审核金额">
            <Input
              style={{ width: 200 }}
              onChange={(e) => {
                amount = e.target.value;
              }}
              allowClear
              placeholder="请输入审核金额"
            />
          </Form.Item>
          {type !== 4 && (
            <Form.Item style={{ marginBottom: 10 }} label="审核天数">
              <Input
                style={{ width: 200 }}
                onChange={(e) => {
                  days = e.target.value;
                }}
                allowClear
                placeholder="请输入审核天数"
              />
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 10 }} label="结算金额">
            <Input
              style={{ width: 200 }}
              onChange={(e) => {
                receiveAmount = e.target.value;
              }}
              allowClear
              placeholder="请输入结算金额"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 10 }} label="备注">
            <Input
              style={{ width: 200 }}
              onChange={(e) => {
                remark = e.target.value;
              }}
              allowClear
              placeholder="请输入备注"
            />
          </Form.Item>
        </div>
      ),
      onOk: async ()=> {
        if(!status){
          message.warning("请选择审核");
          return Promise.reject()
        }
        if(type === 1 || type === 2){
          if(!reletType){
            message.warning("请选择类型");
            return Promise.reject()
          }
        }
        let params = {
          id,
          days,
          amount,
          status,
          receiveAmount,
          remark,
          reletType,
          rentMoney,
          penaltyMoney,
          buyoutMoney,
        };
        try {
          const result = await _exceptionOrderStatus(params)
          if (result.data.code === 200) {
            message.success("操作成功");
            self.getExceptionOrderList(
              self.state.curPage,
              self.state.pageSize,
              self.state.queryFormValues
            );
          } else {
            message.warning(result.data.message);
          }
        } catch (error) {
          message.warning("操作失败");
        }
      },
    });
  }

  //确定订单信息
  getExistOrder(value) {
    let self = this;
    let params = {
      fid:
        localStorage.getItem("type") === "1"
          ? localStorage.getItem("fid")
          : null,
      orderSn: value,
    };
    _queryExistOrder(params)
      .then((res) => {
        if (res.data.data) {
          self.props.form.setFieldsValue({
            id: res.data.data.id,
          });
          self.setState({
            imei: res.data.data.imei,
          });
          Modal.info({
            title: "确定订单信息",
            okText: "确定",
            content: (
              <div>
                <p>订单号：{res.data.data.orderSn}</p>
                <p>套餐名：{res.data.data.pname}</p>
                <p>套餐金额：{res.data.data.pmoney}元</p>
                <p>租期：{res.data.data.rentTime}</p>
                <p>代理商：{res.data.data.fname}</p>
                <p>站点：{res.data.data.sname}</p>
                <p>订单状态：{res.data.data.orderStatusName}</p>
                <p>在租车辆：{res.data.data.imei}</p>
                <p>
                  用户信息：{res.data.data.realname}（{res.data.data.mobile}）
                </p>
              </div>
            ),
            onOk() {
              console.log("Cancel");
            },
          });
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取线下处理列表
  getExceptionOrderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _exceptionOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            exceptionOrderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取退车点列表
  getRepairSiteList() {
    let params = {
      fid:
        localStorage.getItem("type") === "1"
          ? localStorage.getItem("fid")
          : null,
      curPage: 1,
      pageSize: 999,
    };
    _siteRepairBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            repairSiteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取维修项列表
  getBikeRepairList() {
    let params = {
      fid:
        localStorage.getItem("type") === "1"
          ? localStorage.getItem("fid")
          : null,
      curPage: 1,
      pageSize: 999,
    };
    _bikeRepairList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeRepairList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getExceptionOrderList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.handleTime && values.handleTime.length) {
        values.handleTimeStart = moment(values.handleTime[0]).format(
          "YYYY-MM-DD"
        );
        values.handleTimeEnd = moment(values.handleTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.handleTime;
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.typeSearch) {
        values.type = values.typeSearch;
        delete values.typeSearch;
      }
      delete values.periodKeys;
      this.setState({
        queryFormValues: values,
      });
      this.getExceptionOrderList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.handleTime && values.handleTime.length) {
        values.handleTimeStart = moment(values.handleTime[0]).format(
          "YYYY-MM-DD"
        );
        values.handleTimeEnd = moment(values.handleTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.handleTime;
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.typeSearch) {
        values.type = values.typeSearch;
        delete values.typeSearch;
      }
      delete values.periodKeys;
      let params = {
        ...values,
      };
      _exportExceptionOrder(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //获取线下处理详情
  getOrderExceptionDetails(id, type) {
    let params = { id };
    _orderExceptionDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
            abnormalType: res.data.data.type,
            disableForEdit: type === 3 ? true : false,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
    this.getSonsOfRegion(0);
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.id && modalType === 1) {
        values.porderId = values.id;
        delete values.id;
      }
      if (values.areaList) {
        values.cardProvince = values.areaList[0] ? values.areaList[0] : null;
        values.cardCity = values.areaList[1]
          ? values.areaList[1]
          : values.areaList[0];
        delete values.areaList;
      }
      if (localStorage.getItem("type") === "1") {
        values.fid = localStorage.getItem("fid");
      }
      let parameters = [];
      let flg = true;
      values.periodKeys &&
        values.periodKeys.map((item, index) => {
          if (
            values.keyName[`key_${index}`] &&
            values.keyValue[`key_${index}`]
          ) {
            parameters[index] = {
              keyName: values.keyName[`key_${index}`],
              keyValue: values.keyValue[`key_${index}`],
            };
          } else {
            flg = false;
          }
          return item;
        });
      values.repairParam = parameters;
      delete values.keyName;
      delete values.keyValue;
      delete values.periodKeys;
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else if (flg) {
        this.setState({
          submitCheck: true,
        });
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveExceptionOrder(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
                submitCheck: false,
              });
              this.initFormModal();
              this.getExceptionOrderList(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              );
            } else {
              this.setState({
                submitCheck: false,
              });
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              submitCheck: false,
            });
          });
      } else {
        message.warning("检修项参数请填写完整！");
      }
    });
  }
  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
      modalType: null,
      abnormalType: null,
      imei: null,
      isAutoCreate: false,
    });
  }

  //加载地区数据
  getLoadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let params = { parentid: targetOption.value };
    _getSonsOfRegionPackage(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          targetOption.children = areaList;
          targetOption.loading = false;
          let options = [...this.state.options];
          this.setState({
            options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取一级地区
  getSonsOfRegion(parentid) {
    let params = { parentid };
    _getSonsOfRegionPackage(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          this.setState({
            options: [...areaList],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addPeriodItem = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("periodKeys");
    const nextKey = keys.concat(keys[keys.length - 1] + 1);

    form.setFieldsValue({
      periodKeys: nextKey,
    });
  };

  removePeriodItem = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue("periodKeys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      periodKeys: keys.filter((key) => key !== k),
    });
  };

  //自定义input参数
  renderPeriodItems = (formItemLayout, formItemLayoutWithOutLabel) => {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;
    const { initialValues, disableForEdit } = this.state;
    let activeKeys = [];
    let editCustomN = {};
    let editCustomV = {};
    if (initialValues.repairParams) {
      initialValues.repairParams.forEach((item, index) => {
        activeKeys.push(index);
        editCustomN[`key_${index}`] = Number(item.keyName);
        editCustomV[`key_${index}`] = item.keyValue;
      });
    }
    getFieldDecorator("periodKeys", {
      initialValue: activeKeys.length > 0 ? activeKeys : [0],
    });
    const periodKeys = getFieldValue("periodKeys");
    const periodItems = periodKeys.map((k, index) => {
      return (
        <Form.Item label={`维修项参数${index + 1}`} key={`handle_period_${k}`}>
          {getFieldDecorator(`keyName['key_${k}']`, {
            initialValue: editCustomN[`key_${k}`]
              ? editCustomN[`key_${k}`]
              : undefined,
            validateTrigger: ["onChange", "onBlur"],
          })(
            <Select
              placeholder="请选择"
              allowClear
              disabled={disableForEdit}
              style={{ width: "40%", marginRight: 10 }}
              showSearch
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {this.state.bikeRepairList &&
                this.state.bikeRepairList.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          )}
          {getFieldDecorator(`keyValue['key_${k}']`, {
            initialValue: editCustomV[`key_${k}`]
              ? editCustomV[`key_${k}`]
              : undefined,
            validateTrigger: ["onChange", "onBlur"],
          })(
            <Input
              disabled={disableForEdit}
              placeholder="请输入维修金额"
              style={{ width: "40%", marginRight: 10 }}
            />
          )}
          {periodKeys.length > 1 && !disableForEdit ? (
            <Icon
              type="minus-circle-o"
              disabled={periodKeys.length === 1}
              onClick={() => this.removePeriodItem(k)}
            />
          ) : null}
        </Form.Item>
      );
    });
    if (!disableForEdit) {
      periodItems.push(
        <Form.Item key="last" {...formItemLayoutWithOutLabel}>
          <Button
            type="dashed"
            onClick={this.addPeriodItem}
            style={{ width: "40%" }}
          >
            <Icon type="plus" /> 添加参数
          </Button>
        </Form.Item>
      );
    }
    return periodItems;
  };
}
ExceptionOrderList = Form.create({})(ExceptionOrderList);
export { ExceptionOrderList };
